/* eslint-disable import/no-named-as-default */

import Accordion from '@honda-canada/sitecore-jss-components/lib/components/Accordion';
import AccordionItem from '@honda-canada/sitecore-jss-components/lib/components/AccordionItem';
import AnchorContainer from '@honda-canada/sitecore-jss-components/lib/components/AnchorContainer';
import AppBanner from '@honda-canada/sitecore-jss-components/lib/components/AppBanner';
import AppCard from '@honda-canada/sitecore-jss-components/lib/components/AppCard';
import AppHero from '@honda-canada/sitecore-jss-components/lib/components/AppHero';
import CarouselCardsContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselCardsContainer';
import CarouselHero from '@honda-canada/sitecore-jss-components/lib/components/CarouselHero';
import CarouselWithModalContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselWithModalContainer';
import CollapsibleContainer from '@honda-canada/sitecore-jss-components/lib/components/CollapsibleContainer';
import CompareProducts from '@honda-canada/sitecore-jss-components/lib/components/CompareProducts';
import CompareProductsDrawer from '@honda-canada/sitecore-jss-components/lib/components/CompareProductsDrawer';
import ContactUs from '@honda-canada/sitecore-jss-components/lib/components/ContactUs';
import CustomLayoutFactory from '@honda-canada/sitecore-jss-components/lib/components/CustomLayoutFactory';
import DiagonalCard from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCard';
import DiagonalCardContainer from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCardContainer';
import DistributorCard from '@honda-canada/sitecore-jss-components/lib/components/DistributerCard';
import DropdownFilterContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownFilterContainer';
import DropdownToggleContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleContainer';
import DropdownToggleItem from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleItem';
import EngineDetailsCard from '@honda-canada/sitecore-jss-components/lib/components/EngineDetailsCard';
import EngineFeatures from '@honda-canada/sitecore-jss-components/lib/components/EngineFeatures';
import EngineSpecifications from '@honda-canada/sitecore-jss-components/lib/components/EngineSpecifications';
import EngineSeries from '@honda-canada/sitecore-jss-components/lib/components/EngineSeries';
import FAQ from '@honda-canada/sitecore-jss-components/lib/components/FAQ';
import Footer from '@honda-canada/sitecore-jss-components/lib/components/Footer';
import FourColumnLayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/FourColumnLayoutContainer';
import FreeFormText from '@honda-canada/sitecore-jss-components/lib/components/FreeFormText';
import FullWidthCard from '@honda-canada/sitecore-jss-components/lib/components/FullWidthCard';
import Gallery from '@honda-canada/sitecore-jss-components/lib/components/Gallery';
import Header from '@honda-canada/sitecore-jss-components/lib/components/Header';
import Hero from '@honda-canada/sitecore-jss-components/lib/components/Hero';
import HoverCard from '@honda-canada/sitecore-jss-components/lib/components/HoverCard';
import Icon from '@honda-canada/sitecore-jss-components/lib/components/Icon';
import IconCard from '@honda-canada/sitecore-jss-components/lib/components/IconCard';
import Image from '@honda-canada/sitecore-jss-components/lib/components/Image';
import LandingPageCard from '@honda-canada/sitecore-jss-components/lib/components/LandingPageCard';
import LandscapeBoxCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeBoxCard';
import LandscapeCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeCard';
import LayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/LayoutContainer';
import LinkPackageCard from '@honda-canada/sitecore-jss-components/lib/components/LinkPackageCard';
import ModelCard from '@honda-canada/sitecore-jss-components/lib/components/ModelCard';
import PackageCard from '@honda-canada/sitecore-jss-components/lib/components/PackageCard';
import PlanCard2 from '@honda-canada/sitecore-jss-components/lib/components/PlanCard2';
import PortraitCard from '@honda-canada/sitecore-jss-components/lib/components/PortraitCard';
import PreOrderBanner from '@honda-canada/sitecore-jss-components/lib/components/PreOrderBanner';
import PromoBanner from '@honda-canada/sitecore-jss-components/lib/components/PromoBanner';
import QuickLink from '@honda-canada/sitecore-jss-components/lib/components/QuickLink';
import SimilarRecentlyViewedProducts from '@honda-canada/sitecore-jss-components/lib/components/SimilarRecentlyViewedProducts';
import SectionContainer from '@honda-canada/sitecore-jss-components/lib/components/SectionContainer';
import SectionNavigation from '@honda-canada/sitecore-jss-components/lib/components/SectionNavigation';
import ShowcaseCard from '@honda-canada/sitecore-jss-components/lib/components/ShowcaseCard';
import SideNav from '@honda-canada/sitecore-jss-components/lib/components/SideNav';
import SideNavLayout from '@honda-canada/sitecore-jss-components/lib/components/SideNavLayout';
import SideNavScrollIndicator from '@honda-canada/sitecore-jss-components/lib/components/SideNavScrollIndicator';
import Slider from '@honda-canada/sitecore-jss-components/lib/components/Slider';
import Tab from '@honda-canada/sitecore-jss-components/lib/components/Tab';
import Table from '@honda-canada/sitecore-jss-components/lib/components/Table';
import TableStyle2 from '@honda-canada/sitecore-jss-components/lib/components/TableStyle2';
import TabsContainer from '@honda-canada/sitecore-jss-components/lib/components/TabsContainer';
import ThreeColumnLayout from '@honda-canada/sitecore-jss-components/lib/components/ThreeColumnLayout';
import Title from '@honda-canada/sitecore-jss-components/lib/components/Title';
import UnOrderedList from '@honda-canada/sitecore-jss-components/lib/components/UnOrderedList';
import VideoComponent from '@honda-canada/sitecore-jss-components/lib/components/VideoComponent';
import VINSearchApp from '@honda-canada/react-subapp-vin-search/lib/components/VINSearchApp';

const components = new Map();
components.set('Accordion', Accordion);
components.set('AccordionItem', AccordionItem);
components.set('AnchorContainer', AnchorContainer);
components.set('AppBanner', AppBanner);
components.set('AppCard', AppCard);
components.set('AppHero', AppHero);
components.set('CarouselCardsContainer', CarouselCardsContainer);
components.set('CarouselHero', CarouselHero);
components.set('CarouselWithModalContainer', CarouselWithModalContainer);
components.set('CollapsibleContainer', CollapsibleContainer);
components.set('ContactUs', ContactUs);
components.set('CompareProducts', CompareProducts);
components.set('CompareDrawer', CompareProductsDrawer);
components.set('DiagonalCard', DiagonalCard);
components.set('DiagonalCardContainer', DiagonalCardContainer);
components.set('DistributorCard', DistributorCard);
components.set('DropdownFilterContainer', DropdownFilterContainer);
components.set('DropdownToggleContainer', DropdownToggleContainer);
components.set('DropdownToggleItem', DropdownToggleItem);
components.set('EngineDetailsCard', EngineDetailsCard);
components.set('EngineFeatures', EngineFeatures);
components.set('EngineSeries', EngineSeries);
components.set('EngineSpecifications', EngineSpecifications);
components.set('FaqContent', FAQ);
components.set('FiveCellLayout', CustomLayoutFactory);
components.set('FiveColumnLayout', LayoutContainer);
components.set('Footer', Footer);
components.set('FourColumnLayout', FourColumnLayoutContainer);
components.set('FreeFormText', FreeFormText);
components.set('FullWidthCard', FullWidthCard);
components.set('Gallery', Gallery);
components.set('Header', Header);
components.set('Hero', Hero);
components.set('HoverCard', HoverCard);
components.set('Icon', Icon);
components.set('IconCard', IconCard);
components.set('Image', Image);
components.set('LandingPageCard', LandingPageCard);
components.set('LandscapeBoxCard', LandscapeBoxCard);
components.set('LandscapeCard', LandscapeCard);
components.set('LinkPackageCard', LinkPackageCard);
components.set('ModelCard2', ModelCard);
components.set('PackageCard', PackageCard);
components.set('PlanCard2', PlanCard2);
components.set('PortraitCard', PortraitCard);
components.set('PreOrderBanner', PreOrderBanner);
components.set('PromoBanner', PromoBanner);
components.set('QuickLink', QuickLink);
components.set('SimilarRecentlyViewedProducts', SimilarRecentlyViewedProducts);
components.set('SectionContainer', SectionContainer);
components.set('SectionNav', SectionNavigation);
components.set('ShowcaseCard', ShowcaseCard);
components.set('SideNav', SideNav);
components.set('SideNavLayout', SideNavLayout);
components.set('SideNavScrollIndicator', SideNavScrollIndicator);
components.set('SingleColumnLayout', LayoutContainer);
components.set('SiteLogo', () => null); // to prevent 'missing JSS component' error
components.set('SixCellLayout', CustomLayoutFactory);
components.set('Slider', Slider);
components.set('Tab', Tab);
components.set('Table', Table);
components.set('TableStyle2', TableStyle2);
components.set('TabsContainer', TabsContainer);
components.set('ThreeCellLayout', CustomLayoutFactory);
components.set('ThreeColumnLayout', ThreeColumnLayout);
components.set('Title', Title);
components.set('TwoCardUpLayout', CustomLayoutFactory);
components.set('TwoColumnLayout', LayoutContainer);
components.set('UnOrderedList', UnOrderedList);
components.set('VideoComponent', VideoComponent);
components.set('VinRecalls', VINSearchApp);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
